
import * as Sentry from '@sentry/vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import BISList from '@/interfaces/bis_list'
import { CharacterDetails } from '@/interfaces/character'
import { BISListDeleteReadResponse } from '@/interfaces/responses'

@Component
export default class DeleteBIS extends Vue {
  @Prop()
  bis!: BISList

  @Prop()
  character!: CharacterDetails

  details: BISListDeleteReadResponse[] = []

  loading = true

  get canDelete(): boolean {
    return (!this.loading) && this.details.length === 0
  }

  get url(): string {
    return `/backend/api/character/${this.character.id}/bis_lists/${this.bis.id}/delete/`
  }

  mounted(): void {
    this.getDeleteInfo()
  }

  editTeamBIS(teamId: string, memberId: number): void {
    const url = `/team/${teamId}/member/${memberId}/`
    this.$emit('close')
    this.$router.push(url)
  }

  async getDeleteInfo(): Promise<void> {
    // Load the character data from the API
    try {
      const response = await fetch(this.url)
      if (response.ok) {
        // Parse the list into an array of character interfaces and store them in the character data list
        this.details = (await response.json()) as BISListDeleteReadResponse[]
        this.loading = false
      }
      else {
        this.$notify({ text: `Unexpected HTTP Error ${response.status} when fetching BIS List deletion results.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching BIS List deletion results.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async deleteBIS(): Promise<void> {
    try {
      const response = await fetch(this.url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': Vue.$cookies.get('csrftoken'),
        },
      })

      if (response.ok) {
        // Attempt to parse the json, get the id, and then redirect
        this.$emit('close')
        this.$notify({ text: `${this.bis.display_name} deleted successfully!`, type: 'is-success' })
      }
      else {
        this.$notify({ text: `Unexpected response ${response.status} when attempting to delete BIS List.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to delete BIS List.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }
}
