
import * as Sentry from '@sentry/vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CharacterBio from '@/components/character_bio.vue'
import { CharacterDetails } from '@/interfaces/character'
import { CharacterDeleteReadResponse } from '@/interfaces/responses'

@Component({
  components: {
    CharacterBio,
  },
})
export default class DeleteCharacter extends Vue {
  @Prop()
  character!: CharacterDetails

  details: CharacterDeleteReadResponse[] = []

  input = ''

  loading = true

  get canDelete(): boolean {
    return this.input === this.deleteCheck
  }

  get deleteCheck(): string {
    return `${this.character.name} @ ${this.character.world.split(' ')[0]}`
  }

  get url(): string {
    return `/backend/api/character/${this.character.id}/delete/`
  }

  mounted(): void {
    this.getDeleteInfo()
  }

  async getDeleteInfo(): Promise<void> {
    // Load the character data from the API
    try {
      const response = await fetch(this.url)
      if (response.ok) {
        // Parse the list into an array of character interfaces and store them in the character data list
        this.details = (await response.json()) as CharacterDeleteReadResponse[]
        this.loading = false
      }
      else {
        this.$notify({ text: `Unexpected HTTP Error ${response.status} when fetching Character deletion results.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Character deletion results.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async deleteCharacter(): Promise<void> {
    try {
      const response = await fetch(this.url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': Vue.$cookies.get('csrftoken'),
        },
      })

      if (response.ok) {
        // Attempt to parse the json, get the id, and then redirect
        this.$store.dispatch('fetchCharacters')
        this.$emit('close')
        this.$router.push('/', () => {
          Vue.notify({ text: `${this.character.name} (${this.character.world}) deleted successfully!`, type: 'is-success' })
        })
      }
      else {
        this.$notify({ text: `Unexpected response ${response.status} when attempting to delete ${this.character.name}.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to delete ${this.character.name}.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }
}
