
import * as Sentry from '@sentry/vue'
import { Component, Prop } from 'vue-property-decorator'
import BISTable from '@/components/bis_table.vue'
import CharacterBio from '@/components/character_bio.vue'
import DeleteBIS from '@/components/modals/confirmations/delete_bis.vue'
import DeleteCharacter from '@/components/modals/confirmations/delete_character.vue'
import TeamBio from '@/components/team/bio.vue'
import BISListModify from '@/dataclasses/bis_list_modify'
import BISList from '@/interfaces/bis_list'
import { CharacterDetails } from '@/interfaces/character'
import Job from '@/interfaces/job'
import { CharacterScrapeData, CharacterScrapeError } from '@/interfaces/lodestone'
import { CharacterUpdateErrors } from '@/interfaces/responses'
import Team from '@/interfaces/team'
import TeamMember from '@/interfaces/team_member'
import SavageAimMixin from '@/mixins/savage_aim_mixin'

@Component({
  components: {
    BISTable,
    CharacterBio,
    TeamBio,
  },
})
export default class Character extends SavageAimMixin {
  actionsActive: { [bisId: number]: boolean } = {}

  bisShown = true

  character!: CharacterDetails

  @Prop()
  characterId!: string

  errors: CharacterUpdateErrors = {}

  settingsShown = false

  teams: Team[] = []

  teamsShown = false

  loading = true

  updating = false

  get bisListUrl(): string {
    return `/backend/api/character/${this.characterId}/bis_lists/`
  }

  get lodestoneUrl(): string {
    return `/backend/api/lodestone/${this.character.lodestone_id}/`
  }

  get teamsUrl(): string {
    return `/backend/api/team/?char_id=${this.characterId}`
  }

  get url(): string {
    return `/backend/api/character/${this.characterId}/`
  }

  created(): void {
    this.fetchChar(false)
    this.fetchTeams()
  }

  deleteBIS(bis: BISList): void {
    // Prompt deletion first before sending an api request (we'll use a modal instead of javascript alerts)
    const { path } = this.$route // Store the path we were on so if the modal navigates off we don't try to run bad code
    this.$modal.show(DeleteBIS, { bis, character: this.character }, { }, { closed: () => { if (this.$route.path === path) this.fetchChar(true) } })
  }

  deleteChar(): void {
    // Prompt deletion first before sending an api request (we'll use a modal instead of javascript alerts)
    this.$modal.show(DeleteCharacter, { character: this.character })
  }

  async duplicateBIS(bis: BISList): Promise<void> {
    // Create a shallow copy of the bis list, change the name and send a post request to the endpoint
    const dupedBis = BISListModify.buildEditVersion(bis)
    dupedBis.name = `Copy of ${bis.display_name}`
    try {
      const response = await fetch(this.bisListUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
        body: JSON.stringify(dupedBis),
      })

      if (response.ok) {
        this.$notify({ text: 'BIS List copied successfully!', type: 'is-success' })
      }
      else {
        this.$notify({ text: 'BIS List could not be copied.', type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to create BIS List.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async fetchChar(reload: boolean): Promise<void> {
    // Load the character data from the API
    try {
      const response = await fetch(this.url)
      if (response.ok) {
        // Parse the list into an array of character interfaces and store them in the character data list
        this.character = (await response.json()) as CharacterDetails
        this.loading = false
        if (reload) this.$forceUpdate()
        document.title = `${this.character.name} @ ${this.character.world} - Savage Aim`
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Character.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async fetchTeams(): Promise<void> {
    // Load the character data from the API
    try {
      const response = await fetch(this.teamsUrl)
      if (response.ok) {
        // Parse the list into an array of teams and store them in the teams data list
        this.teams = (await response.json()) as Team[]
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Character's Team List.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  // Return the details of the Job the character plays in the given Team
  getJob(team: Team): Job {
    return team.members.find((tm: TeamMember) => tm.character.id === parseInt(this.characterId, 10))!.bis_list.job
  }

  // WS reload
  async load(): Promise<void> {
    this.fetchChar(true)
    this.fetchTeams()
  }

  async saveDetails(): Promise<void> {
    // Update fields for the Character
    this.errors = {}
    const body = JSON.stringify(this.character)
    try {
      const response = await fetch(this.url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
        body,
      })

      if (response.ok) {
        this.$notify({ text: 'Successfully updated!', type: 'is-success' })
        this.$forceUpdate()
      }
      else {
        super.handleError(response.status)
        this.errors = (await response.json() as CharacterUpdateErrors)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to update Character.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  toggleActions(bisId: number): void {
    this.actionsActive[bisId] = !(this.actionsActive[bisId] || false)
    this.$forceUpdate()
  }

  async updateChar(): Promise<void> {
    if (this.updating) return

    this.updating = true
    // Reload data from the Lodestone, and send an update request to the API.
    // We just need to update the name, world, and image url
    try {
      const response = await fetch(this.lodestoneUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
      })
      if (response.ok) {
        const json = await response.json() as CharacterScrapeData
        // Update the local character and use the same function for updating alias
        this.character.avatar_url = json.avatar_url
        this.character.name = json.name
        this.character.world = `${json.world} (${json.dc})`
        await this.saveDetails()
      }
      else {
        const json = await response.json() as CharacterScrapeError
        this.$notify({ text: `Received error when attempting to update Character details from Lodestone; ${json.message}`, type: 'is-danger' })
      }
    }
    catch (err) {
      this.$notify({ text: `Unexpected error ${err} when attempting to update Character details.`, type: 'is-danger' })
      Sentry.captureException(err)
    }
    finally {
      this.updating = false
    }
  }

  async verify(): Promise<void> {
    // Send a verification request to the API. Since it's a celery based system, there's no need to reload
    if (this.character.verified) return // No need running this function if we're already verified

    try {
      const response = await fetch(`${this.url}verify/`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
      })
      if (response.ok) {
        this.$notify({ text: 'Verification requested, please wait!', type: 'is-success' })
      }
      else if (response.status === 404) {
        // Status 404 on this page likely means the character is verified
        this.fetchChar(true)
      }
      else {
        this.$notify({ text: `Unexpected HTTP status ${response.status} received when attempting to add Character to verification queue.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to add Character to verification queue.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  // Show code for the tabs
  showBIS(): void {
    this.showNone()
    this.bisShown = true
  }

  showNone(): void {
    this.bisShown = false
    this.settingsShown = false
    this.teamsShown = false
  }

  showSettings(): void {
    this.showNone()
    this.settingsShown = true
  }

  showTeams(): void {
    this.showNone()
    this.teamsShown = true
  }
}
